import { Graph } from "../services/aStarVisualizer/Graph";
import React, {useState} from "react";
import { LuEraser } from "react-icons/lu";

const optionToChar = {
    "start": '&',
    "end": "#",
    "wall": '@',
    "eraser": ' ',
}
const pathChar = '$';
const visitedChar = '·';

const allChars = {
    ...optionToChar,
    "path": pathChar,
    "visited": visitedChar
};
console.log(optionToChar)
const charToOption = Object.fromEntries(
    Object.entries(optionToChar).map(([key, value]) => [value, key])
);

const optionToColor = {
    "start": "text-blue-500",
    "end": "text-red-500",
    "wall": "text-white",
    "eraser": "text-white",
}

const AStarVisualizerPage = ({cols, rows}) => {
    const [graph, setGraph] = useState(() => new Graph(cols, rows, allChars));
    const [pencil, setPencil] = useState('');
    const [version, setVersion] = useState(0);

    const [startVertex, setStartVertex] = useState(null);
    const [endVertex, setEndVertex] = useState(null);

    const [mouseDown, setMouseDown] = useState(false);

    const handleRadioChange = (event) => {
        setPencil(event.target.value);
    };

    const runAStar = async () => {
        graph.clear();
        let reconstructedPath = graph.aStar(startVertex, endVertex);
        if (reconstructedPath === null) return;
        for (let i = 0; i < reconstructedPath.length; i++) {
            let vertex = graph.vertices.get(reconstructedPath[i])
            if (charToOption[vertex.getChar()] === "start" || charToOption[vertex.getChar()] === "end") continue;
            vertex.setChar(pathChar);
            setVersion(version + 1);
        }
    }

    const reset = () => {
        setGraph(new Graph(cols, rows));
        setVersion(0);
        setStartVertex(null);
        setEndVertex(null);
    }

    // Create dynamic regex patterns using template literals
    const topLeftCorner = new RegExp(`^0,0$`);
    const topRightCorner = new RegExp(`^${cols - 1},0$`);
    const bottomLeftCorner = new RegExp(`^0,${rows - 1}$`);
    const bottomRightCorner = new RegExp(`^${cols - 1},${rows - 1}$`);
    const leftEdge = new RegExp(`^0,.*$`);
    const rightEdge = new RegExp(`^${cols - 1},.*$`);
    const topEdge = new RegExp(`^.*,0$`);
    const bottomEdge = new RegExp(`^.*,${rows - 1}$`);

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            backgroundColor: "#161616",
            height: "100vh",
            fontWeight: 200,
            color: "white",
            fontFamily: 'Iosevka Web, Helvetica Neue',
        }}>
            <div className="flex flex-col gap-y-5">
                <div className="projects-header w-full text-left flex flex-row justify-between">
                    <h2>A* (STAR) <span className='italic-thin'>PATHFINDER</span><span className='accent'>:</span></h2>
                    <a className="btn-primary h-min text-nowrap" href="https://github.com/marcoaobatista/AStarVisualizer.git" target="_blank">GitHub ↗</a>
                </div>

                <div
                    onMouseDown={() => {
                        setMouseDown(true)
                    }}
                    onMouseUp={() => {
                        setMouseDown(false)
                    }}
                    onMouseLeave={() => {
                        setMouseDown(false)
                    }}
                    style={{
                        cursor: "cell",
                        display: 'grid',
                        gridTemplateColumns: `repeat(${cols}, 1fr)`,
                        gridTemplateRows: `repeat(${rows}, 1fr)`,
                    }}>
                    {Array.from(graph.getVertices().values()).map((vertex) => (
                        <div
                            onMouseDown={() => {
                                if (vertex.getIsFrame()) {
                                    return;
                                }
                                if (pencil === "start" && startVertex == null) {
                                    vertex.setChar(optionToChar["start"]);
                                    setStartVertex(vertex.id);
                                } else if (pencil === "end" && endVertex == null) {
                                    vertex.setChar(optionToChar["end"]);
                                    setEndVertex(vertex.id)
                                } else if (pencil === "wall") {
                                    vertex.setChar(optionToChar["wall"]);
                                    vertex.setActive(false)
                                }
                                setVersion(version + 1);
                            }}
                            onMouseMove={() => {
                                if (!mouseDown) return;
                                graph.clear();

                                if (pencil === "eraser") {
                                    if (vertex.getChar() === optionToChar["start"]) {
                                        vertex.setChar(" ");
                                        setStartVertex(null);
                                    } else if (vertex.getChar() === optionToChar["end"]) {
                                        vertex.setChar(" ");
                                        setEndVertex(null);
                                    }
                                    vertex.setChar(" ");
                                    vertex.setActive(true);
                                } else if (pencil === "wall") {
                                    vertex.setChar(optionToChar["wall"]);
                                    vertex.setActive(false)
                                }

                                setVersion(version + 1);
                            }}
                            className={"select-none h-2 w-1 text-[8px] md:h-4 md:w-2 md:text-[12px] lg:h-8 lg:w-4 lg:text-2xl flex items-center justify-center "
                                + (vertex.getChar() === optionToChar["wall"] ? "text-white " : " ")
                                + (vertex.getChar() === visitedChar ? "text-yellow-500 " : " ")
                                + (vertex.getChar() === optionToChar["start"] ? "text-blue-500 " : " ")
                                + (vertex.getChar() === pathChar ? "text-green-500 " : " ")
                                + (vertex.getChar() === optionToChar["end"] ? "text-red-500 " : " ")} id={vertex.id}
                            key={vertex.id}>{

                            // Determine what to display based on regex tests
                            topLeftCorner.test(vertex.id) ||
                            topRightCorner.test(vertex.id) ||
                            bottomLeftCorner.test(vertex.id) ||
                            bottomRightCorner.test(vertex.id)
                                ? '+'
                                : leftEdge.test(vertex.id) ||
                                rightEdge.test(vertex.id)
                                    ? '!'
                                    : topEdge.test(vertex.id) ||
                                    bottomEdge.test(vertex.id)
                                        ? '—'
                                        : vertex.getChar()
                        }</div>
                    ))}
                </div>
                <div className="flex flex-row justify-between w-full">
                    <div className="grid grid-cols-4 grid-rows-1 w-fit">
                        {Object.keys(optionToChar).map((option, index) =>
                            <div key={`selector-${option}`} className="uppercase text-center flex flex-col">
                                <input className="hidden" type="radio" id={option} name="pencil" value={option}
                                       checked={pencil === option}
                                       onChange={handleRadioChange}/>
                                <label
                                    className={`italic select-none font-semibold cursor-pointer size-12 border-y border-white text-2xl flex justify-center text-center items-center ${optionToColor[option]} ${
                                        index === 0 ? 'border-l' : index === (Object.keys(optionToChar).length - 1) ? 'border-r' : ''
                                    }`} htmlFor={option}>
                                    {option === "eraser" ? <LuEraser/> : optionToChar[option]}
                                </label>
                                <span
                                    className={`text-[12px] font-normal transition-all ${(pencil === option) ? "border-t-4" : ""}`}>
                                {option}
                            </span>
                            </div>
                        )}
                    </div>
                    <div className="h-fit flex gap-4">
                        <button className="btn-primary" onClick={() => reset()}>clear</button>
                        <button className="btn-primary" onClick={() => runAStar()}>start</button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default AStarVisualizerPage