import React from 'react';
import '../styles/Experience.css'

class Experience extends React.Component{
    render(){
        const experiences = [
            {
                orgUrl: "https://engineering.msu.edu/",
                img: "MSULogo.png",
                period: "SPRING 2025 - PRESENT",
                organization: "MSU College of Engineering",
                role: "Student Front-end Web Assistant",
                desc: [
                    "Assist with website updates, content migration, and layout improvements using CMSs like Sitecore and Drupal",
                    "Implement and integrate accessibility features to enhance website usability and compliance",
                    "Handle web update requests efficiently through a ticketing system while maintaining a focus on user experience and design standards"
                ],
            },
            {
                orgUrl: "https://tecnisub.com.br/",
                img: "tecnisub.png",
                period: "SUMMER 2024",
                organization: "TECNISUB",
                role: "Mechanical Design and Software Development Intern",
                desc: [
                    "Devised a catalog application using React, TypeScript, AWS Amplify, and Tailwind CSS, improving organization and retrieval of 3D models and technical drawings",
                    "Integrated 3D model viewing with React Three Fiber, added search functionality, and provided secure authentication, enhancing user experience, efficiency, and security",
                    "Interfaced with a team of 5 technicians and mechanical engineers to ensure software user-friendliness, technical requirements, and informational needs",
                    "Designed and modeled products using CAD software, contributing to product development, certification process, and ensuring designs met technical standards",
                ],
            },
            {
                orgUrl: "",
                img: "redcall.png",
                period: "SPRING 2024 - PRESENT",
                organization: "Redcall",
                role: "Creator/ Developer",
                desc: [
                    "Devised a user-friendly mobile notification app for a local blood center leveraging Flutter and Firebase within a 3 month period, aimed at donor engagement by sending timely alerts during critical blood shortages",
                    "Spearheaded its Idealization and development, mastering and implementing a tech stack to establish an innovative method for attracting new donors and motivating existing ones",
                ],
            },
            {
                orgUrl: "https://r3animal.org",
                img: "r3animal.png",
                period: "WINTER 2020 - PRESENT",
                organization: "R3 Animal",
                role: "Volunteer/ Webmaster",
                desc: [
                    "Conducted regular website updates to share conservation institution's deeds to over +1000 people monthly",
                    "Developed a cost-effective strategy to launch an online merchandise store for fundraising, operations support, and wildlife conservation awareness, with zero upfront costs",
                    "Trained employees on how to conduct simple platform updates by themselves, maximizing posting speeds by 80% and overall content diversity on webpage",
                ],
            },
        ]
        return (
            <div className="section" id="experience">
                <div className="work-header">
                    <h2>MY <span className='italic-thin'>JOURNEY </span>SO FAR<span className='accent'>:</span></h2>
                </div>

                <div className="experience-list">
                        <div className="experience-duck-div">
                            <p>{new Date().getFullYear()}</p>
                            <img className="experience-duck" src="duck.png" />
                        </div>
                        {experiences.map((exp, i) => {
                            return (
                                <div className={i % 2 === 0 ? "experience experience-right" : "experience experience-left"}>
                                    <div className="experience-img">
                                        <a href={exp.orgUrl}><img src={exp.img} /></a>
                                    </div>
                                   
                                    <p className="caption">{exp.period}</p>
                                    <h3 className='organization'>{exp.organization}</h3>
                                    <p className="caption role">{exp.role}</p>
                                    {exp.desc.map((e, i) => {
                                        return <p>{e}</p>
                                    })}
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }
}

export default Experience;