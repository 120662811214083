import React from 'react';
import '../styles/Projects.css';

class Projects extends React.Component{
    render(){
        const projects = [
            {
                techStack:[
                    "React.js",
                    "DSA",
                    "Tailwind CSS",
                ],
                title: "A* (Star) Visualizer",
                desc: "Interactive tool that visually demonstrates how the A* pathfinding algorithm navigates grids to find the shortest path.",
                url: "/astar-visualizer",
            },
            {
                techStack:[
                    "Python",
                    "REST APIs",
                ],
                title: "Latest Bops",
                desc: "A script designed to optimize phone storage by maintaining a smaller Spotify playlist that includes the latest tracks from your main playlist, organized from newest to oldest.",
                url: "https://github.com/marcoaobatista/Latest-Bops",
            },
            {
                techStack:[
                    "React.js",
                    "REST APIs",
                    "Tailwind CSS"
                ],
                title: "Bible Verses",
                desc: "Saved Bible verses for personal memorization, seamlessly integrated with the Bible app using Apple Shortcuts.",
                url: "/bible-verses",
            },
            {
                techStack:[
                    "Javascript",
                    "HTML",
                    "CSS"
                ],
                title: "Done Todo List",
                desc: "A fully functional todo list web application with IndexedDB.",
                url: "https://github.com/marcoaobatista/Done-To-do-List-App",
            },
            {
                techStack:[
                    "Python"
                ],
                title: "Python Calculator",
                desc: "A calculator program written in Python.",
                url: "https://github.com/marcoaobatista/Python-Calculator",
            },
            {
                techStack:[
                    "Python"
                ],
                title: "Python Tic Tac Toe",
                desc: "TicTacToe game program written in Python.",
                url: "https://github.com/marcoaobatista/Python-Tic-Tac-Toe",
            },
        ]
        return (
            <div id="projects">
                <div className="projects-header">
                    <h2><span className='italic-thin'>PROJECTS </span>TO QUACK ABOUT<span className='accent'>:</span></h2>
                </div>
                <div className="projects-list-container">
                    <div className="projects-list">
                        {projects.map((proj, i) => {
                            return (
                                <a href={proj.url}>
                                    <div className="project">
                                        <div>
                                            <div className="project-header">
                                                <h3>{proj.title}</h3>
                                                <div>
                                                {proj.techStack.map((e, i) => {
                                                    if(proj.techStack.length === i + 1){
                                                        return <span className="accent">{e}</span>
                                                    } 
                                                    return <span className="accent">{e+", "}</span>
                                                })}
                                                </div>
                                            </div>
                                            <p className="caption">{proj.desc}</p>
                                        </div>
                                        <span className="project-arrow">↗</span>
                                    </div>
                                </a>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default Projects;