import React from 'react'
import '../styles/About.css'
class About extends React.Component{
    render(){
        const tech_stack = [
            "Python",
            "C++",
            "Javascript ES6+",
            "Dart",
            "ASM",
            "Flutter",
        ]
        return (
            <div className="section" id='about'>
                <div className="about-header">
                    <h2>LET ME INTRODUCE <span className='italic-thin'>MYSELF<span className='accent'>,</span></span></h2>
                </div>
                
                <div className='about-content'>
                    <img src='photo.jpg' />
                    <div className='about-text'>
                        <p>I am a duck aficionado and software debugger in my spare time. Currently studying Computer Science at <a className="about-blue-link" href="http://msu.edu">Michigan State University</a></p>
                        <p>Here are some technologies I have been working with:</p>
                        <ul className="tech-stack">
                            {tech_stack.map((tech_item, i) => {
                            return <li>{tech_item}</li>
                            })}
                        </ul>
                        <p>Building handy tools and making people’s lives better is my jam </p>
                        <div className="about-links">
                            <a href="https://github.com/marcoaobatista" target="_blank">Github</a>
                            <a href="https://drive.google.com/open?id=1JwwLgxzocmj9YPLf3kQE0Po3uwL3B_Fv&usp=drive_fs" target="_blank">Resume</a>
                            <a href="https://www.linkedin.com/in/marco-antonio-de-oliveira-batista-58778415a/" target="_blank">LinkedIn</a>
                            <a href="mailto:demarc39@msu.edu" target="_blank">Email</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;