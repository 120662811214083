import { useEffect, useState } from 'react'
import { getVerseAndRef } from '../services/bibleVersesService'
import { hover } from '@testing-library/user-event/dist/hover';

function BibleVersesPage() {
  const [ref, setRef] = useState("")
  const [verse, setVerse] = useState("")
  const [verseIndex, setVerseIndex] = useState(0);

  const getVerse = async (index = null) => {
    const { verse, ref, verseIndex } = await getVerseAndRef(index)

    setVerseIndex(verseIndex)
    setVerse(verse)
    setRef(ref)
  }

  useEffect(() => {
    const loadData = async () => {
      getVerse()
    }
    loadData()
  }, [])

  const nextVerse = () => {
    setVerseIndex(verseIndex + 1)
    getVerse(verseIndex + 1)
  }

  const prevVerse = () => {
    setVerseIndex(verseIndex - 1)
    getVerse(verseIndex - 1)
  }

  const changeVerseBtn = {
    cursor: 'pointer',
    padding: '10px',
    border: 'none',
    backgroundColor: 'transparent',
    color: 'white',
    fontSize: 18
  }

  return (
    <div style={{display: "flex", flexDirection: "column",textAlign: "center",alignItems:"center",alignContent:"center",justifyContent:"center",backgroundColor: "#161616", height: "100vh"}}>
      <div style={{padding: "40px"}}>
        <h1 style={{fontFamily: 'Georgia', color: "white", margin: 0}}>{ref}</h1>
        <p style={{fontFamily: 'Iosevka Web, Helvetica Neue',color: "white", fontSize: "16px"}}>{verse}</p>
        
      </div>
      <div style={{display: 'flex',justifyContent:'center', gap: '30px'}}>
          <button onClick={prevVerse} style={changeVerseBtn} className='changeVerseBtn'>{'[ < ]'}</button>
          <button onClick={nextVerse} style={changeVerseBtn} className='changeVerseBtn'>{'[ > ]'}</button>
        </div>
    </div>
  )

}

export default BibleVersesPage
